<template>
  <div class="homepage">
    <div class="header">
       <img class="logo" src="/images/logo1.png" alt />
      <el-menu
        :default-active="activeIndex"
        class="nav-menu"
        mode="horizontal"
        @select="handleSelect"
      >
        <el-menu-item index="1">首页</el-menu-item>
        <el-menu-item index="2">关于</el-menu-item>
        <el-menu-item index="3">溯源</el-menu-item>
      </el-menu>
      <section>
        <div class="login-register" v-show="loginText === '登录'">
          <div class="loginbtn" @click="toLoginPage">
            {{ loginText }}
          </div>
          <el-divider direction="vertical"></el-divider>
          <div class="loginbtn" @click="toRegisterPage">注册</div>
        </div>
        <div v-show="loginText !== '登录'" class="textbtn" @click="toLoginPage">
          {{ loginText }}
        </div>
      </section>
    </div>
    <div class="main">
      <div class="block">
        <el-carousel height="550px">
          <el-carousel-item v-for="item in bannerList" :key="item">
            <img :src="item" alt />
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="smallSpan search">
        <div class="inputWithSelect">
          <el-input placeholder="请输入产品名称">
            <i class="el-icon-search" slot="prepend"></i>
          </el-input>
        </div>
        <el-button class="searchButton">搜索</el-button>
      </div>
      <div class="smallSpan cardmodule" @click="toLoginPage">
        <el-row :gutter="40" >
          <el-col :span="12" v-for="(o, index) in cardList" :key="index" >
            <el-card
              :body-style="{ padding: '0px' }"
              style="margin-bottom: 30px"
            >
              <div class="imgWrapper">
                <img :src="o.backImg" class="image bgimg" />
                <!--  <div class="textWrapper">
                                    <div class="frontimg">
                                        <img :src="o.front" class="image ">
                                    </div>
                                    <span>{{o.discripe}}</span>
                </div>-->
              </div>
            </el-card>
          </el-col>
        </el-row>
      </div>
      <div class="news">
        <div class="smallSpan newsWrapper">
          <div class="newsTitle">
            <span class="text">新闻资讯</span>
            <span class="square"></span>
            <!-- <img src="/images/news.png" alt /> -->
          </div>
          <div class="more" @click="viewList()">
            <span >查看更多</span>
          </div>
          <el-divider></el-divider>
          <div class="list"  @click="viewnews">
            <el-row
            class="newsListRow"
            :gutter="20"
            v-for="(o, index) in listList"
            :key="index"
            :data-key="index"
            :offset="(index + 1) % 2 === 0 ? 1 : 0"
          >
            <el-col :span="4" :data-key="index" class="preImgWrapper">
              <img class="preImg" :src="o.preImg" alt />
            </el-col>
            <el-col :span="15" :offset="1">
              <div class="elTextWrapper">
                <p class="textTitle" :data-key="index">{{ o.title }}</p>
                <p class="textContent">{{ o.content }}</p>
                <p class="textTime">{{ o.time }}</p>
              </div>
            </el-col>
          </el-row>
          </div>
          
          <!-- <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
            <el-tab-pane class="eltabpane" label="" name="first">
             
            </el-tab-pane>
            <el-tab-pane label="配置管理" name="second">企业动态</el-tab-pane>
            <el-tab-pane label="角色管理" name="third">最新活动</el-tab-pane>
          </el-tabs> -->
        </div>
      </div>
      <div class="resource">
        <div class="smallSpan newsWrapper">
          <div class="newsTitle">
            <span class="text">溯源资源</span>
            <span class="square"></span>
            <!-- <img src="/images/resource.png" alt /> -->
          </div>
          <el-row :gutter="20" class="resourceRow">
            <el-col
              :span="11"
            >
              <div class="reCard">
                <p class="title"><img src="/images/gisilogo.jpg" alt="" srcset=""></p>
               <a class="content" target="_blank" href="http://www.gs1cn.org/" >中国物品编码中心</a>
              </div>
            </el-col>
             <el-col
              :span="11"
              :offset=2
            >
              <div class="reCard">
                <p class="title"><img src="/images/gisilogo.jpg" alt="" srcset=""></p>
                <a class="content long" target="_blank" href="http://www.chinatrace.org/" >国家食品(产品)安全追溯平台</a>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
    <div class="footer">
      <div class="smallSpan flex">
        <div class="relateWrapper">
          <ul>
            <li>关于我们</li>
            <li class="canclick" @click="topage('newsaboutus')">公司简介</li>
            <li class="canclick" @click="topage('newsyuanjing')">愿景与使命</li>
            <!-- <li>在线留言</li> -->
            <li class="canclick" @click="topage('newszhaonai')">招纳贤士</li>
          </ul> 
          <ul>
            <li>产品与平台</li>
            <li class="canclick" @click="topage('newssoft')">软件</li>
            <li class="canclick" @click="topage('newshard')">硬件</li>
            <li class="canclick" @click="topage('newsservice')">服务</li>
          </ul>
          <ul>
            <li>成功案例</li>
            <li  class="canclick" @click="topage('newsgover')">政府单位</li>
            <li  class="canclick" @click="topage('newsfinance')">金融行业</li>
            <li  class="canclick" @click="topage('newscompony')">企业公司</li>
          </ul>
          <ul>
            <li>
              <!-- <img src="/images/logowhite.png" alt /> -->
            </li>
            <li class="tel">010-56548358</li>
            <li class="tel">13611080558</li>
            <li class="tel">13366955736</li>
            <li>周一至周五：8:30~18:00</li>
          </ul>
        </div>
      </div>
      <div class="footerBottom">
        <p>
          北京中软汇金科技有限公司 版权所有 2018-2028
          <a class="alink" href="https://beian.miit.gov.cn/#/Integrated/index"
            >京ICP备18048146号-2</a
          >
          <a
            class="alink"
            href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11011402022131"
          >
            <img
              class="gxbImg"
              src="/images/gxb.png"
              alt
            />京公网安备11011402011131号
          </a>
        </p>
        <p>
          Beijing China Software Huijing Technology CO.,LTD Powered by MetInfo
          6.2.0 ©2008-2020 MetInfo Inc.
        </p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      loginText: "登录",
      activeIndex: "1",
      bannerList: [
        "/images/banner1.png",
        "/images/banner2.jpg",
        "/images/banner3.jpg",
        "/images/banner4.jpg",
      ],
      cardList: [
        {
          backImg: "/images/bgone.png",
          front: "/images/system_management.png",
          discripe: "系统管理",
        },
        {
          backImg: "/images/bgtwo.png",
          front: "/images/product_management.png",
          discripe: "产品管理",
        },
        {
          backImg: "/images/bgthree.png",
          front: "/images/mall.png",
          discripe: "商城",
        },
        {
          backImg: "/images/bgfore.png",
          front: "/images/learn_more.png",
          discripe: "了解更多",
        },
      ],
      activeName: "first",
      listList: [
        {
          preImg: "/images/news.jpg",
          title: "中国物品编码中心深圳分中心赴东莞调研进口冻品追溯数据互联互通工作",
          content:
            "1月7日下午，深圳市市场监督管理局指挥协调处与中国物品编码中心深圳分中心食品追溯团队赴东莞市集中监管仓、东莞市市场监督管理局就东莞进口冻品追溯监管情况调研，探讨深莞两地...",
          time: "发表时间: ：2021-01-25 10:58:31",
        },
        {
          preImg: "/images/news1.jpg",
          title: "中国物品编码中心召开中层干部会议传达学习全国市场监管工作会议精神",
          content:
            "2021年2月4日上午，中国物品编码中心（以下简称编码中心）召开年终中层干部会议，编码中心主任张成海、副主任罗秋科、总工程师李建辉、纪委书记岳善...",
          time: "发表时间: 2021-01-16 11:47:29",
        },
        {
          preImg: "/images/news2.jpg",
          title: "中国物品编码中心广西分中心赴广西威利方舟科技有限公司开展UDI调研工作",
          content:
            "为深入了解医疗器械唯一标识（UDI）的应用情况，近日，中国物品编码中心广西分中心赴广西威利方舟科技有限公司开展调研工作，实地了解企业在实施UDI过程中的问题和需求...",
          time: "发表时间: 2020-09-24 11:09:19",
        },
      ],
      resourceList: [
        {
          title: "国家物品编码体系",
          content:
            "物品信息与“人”（主体）、“财”信息并列为社会经济运行的三大基础信息。与“人”、“财”信息相比，“物”的品种繁杂、属性样理主体众多；运行过程复杂。如何真正建立起“物”的信息资源系统，实现全社会的信息交换、资源共享，一直是各界关注的焦点，也是未解难题。物品编码是数字化的“物”信息，是现代化、信息化的基石。近年来不断出现的物...",
        },
        {
          title: "国家物品编码体系",
          content:
            "物品信息与“人”（主体）、“财”信息并列为社会经济运行的三大基础信息。与“人”、“财”信息相比，“物”的品种繁杂、属性样理主体众多；运行过程复杂。如何真正建立起“物”的信息资源系统，实现全社会的信息交换、资源共享，一直是各界关注的焦点，也是未解难题。物品编码是数字化的“物”信息，是现代化、信息化的基石。近年来不断出现的物...",
        },
        {
          title: "国家物品编码体系",
          content:
            "物品信息与“人”（主体）、“财”信息并列为社会经济运行的三大基础信息。与“人”、“财”信息相比，“物”的品种繁杂、属性样理主体众多；运行过程复杂。如何真正建立起“物”的信息资源系统，实现全社会的信息交换、资源共享，一直是各界关注的焦点，也是未解难题。物品编码是数字化的“物”信息，是现代化、信息化的基石。近年来不断出现的物...",
        },
      ],
    };
  },
  created() {
    var token = localStorage.getItem("token");
    if (token) {
      this.loginText = localStorage.getItem("username");
    }
  },
  methods: {
    toLoginPage() {
      this.$router.push("/login");
    },
    toRegisterPage() {
      this.$router.push("/register");
    },
    handleSelect(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClick(tab, event) {
      console.log(tab, event);
    },
    viewList(){
      window.open(this.$router.resolve({path:"/newslist"}).href,'_blank')
    },
    viewnews(e){
     // console.log(e.target.dataset.key);
    let id = e.target.dataset.key;
    if(id){
      switch (id) {
        case '0': 
           window.open(this.$router.resolve({path:"/news"}).href,'_blank')
          break;
         case '1': 
         window.open(this.$router.resolve({path:"/news1"}).href,'_blank')
          break;
           case '2': 
           window.open(this.$router.resolve({path:"/news2"}).href,'_blank')
          break;
        default:
          break;
      }
      
    }
    },
    topage(path){
       this.$router.push(`/${path}`);
    }
  },
};
</script>

<style lang='scss' scoped>
.canclick{
  cursor: pointer;
}
.homepage {
  width: 100%;
}
.header {
  width: 60%;
  margin: 10px auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .login-register {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .loginbtn {
      font-size: 12px;
      color: #04c9a0;
      padding: 5px 15px;
      border: 1px solid #04c9a0;
      border-radius: 6px;
      cursor: pointer;
    }
    .textbtn {
      font-size: 12px;
      color: #04c9a0;
      padding: 5px 15px;
      cursor: pointer;
    }
  }
}
.smallSpan {
  width: 60%;
  margin: 50px auto;
}
.search {
  text-align: center;
  .inputWithSelect,
  .searchButton {
    display: inline-block;
  }
  .inputWithSelect {
    width: 60%;
    height: 56px;
    font-size: 16px;
    .el-input {
      height: 55px;
    }
  }
  .searchButton,
  .searchButton:hover {
    width: 12%;
    height: 56px;
    vertical-align: bottom;
    background: #00c9a1;
    color: #fff;
    border-radius: 0 10px 10px 0;
  }
  .searchButton:hover {
    background: #00d3a1;
  }
}
.image {
  width: 100%;
  display: block;
}
.imgWrapper {
  position: relative;
  display: flex;
  .bgimg {
    height: 100%;
  }
  .textWrapper {
    justify-content: center;
    align-content: center;
    .frontimg {
      height: 82px;
      margin-bottom: 10px;
      text-align: center;
    }
    span {
      color: #fff;
      font-size: 16px;
    }
  }
}

.news {
  margin-top: 25px;
  background: rgb(242, 249, 248);
}
.newsWrapper {
  position: relative;
  padding: 30px 0;
  .newsTitle {
    text-align: center;
    .text {
      font-size: 2.2em;
      font-weight: 500;
      display: block;
    }
    .square {
      display: block;
      width: 12.6em;
      height: 1.3em;
      background: #dad8d8;
      margin: -1.3em auto 0
    }
  }
  .more {
    display: inline-block;
    position: absolute;
    right: 0;
    color: #04c9a0;
    cursor: pointer;
    span:after {
      position: absolute;
      display: inline-block;
      width: 100%;
      height: 20px;
      content: "";
      transform: translate(5%, 1%);
      background: url("/images/more.png") no-repeat;
      background-size: 15px;
    }
  }
  .eltabpane {
    background: #ffffff;
  }
  .newsListRow {
    display: flex;
    padding: 20px;
    border-bottom: 1px solid #e6e6e6;
    .preImgWrapper{
      display: flex;
      .preImg{
        width: 100%;
      }
    }
    .elTextWrapper {
      display: inline-block;
      vertical-align: middle;
      padding: 10px 0;
      .textTitle {
        cursor: pointer;
        color: #000000;
        font-size: 20px;
        margin-top: 20px;
      }
      .textContent {
        color: #666666;
        font-size: 15px;
        margin-top: 20px;
        line-height: 1.5;
      }
      .textTime {
        color: #999999;
        font-size: 13px;
        margin-top: 20px;
      }
    }
  }
}
.resourceRow {
  margin-top: 30px;
}
.reCard {
  background: #f5f5f5;
  border-radius: 20px;
  padding: 1.2rem;
  text-align: center;
  line-height: 1.5;
  display: flex;
  flex-wrap: nowrap;
  .title {
    width: 26%;
  }
  .content {
  width: 100%;
    text-align: left;
    font-size: 1.4em;
    line-height: 3.5em;
    color: #000;
    margin-left: 1em;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    text-decoration: none;
  }
  // .long{
  //  width: 58%;
  // }
}
.footer {
  // height: 340px;
  background: #333635;
  .flex {
    display: flex;
    flex-direction: column;
    .relateWrapper {
      margin-top: 30px;
      display: flex;
      justify-content: space-between;
      width: 100%;
      ul {
        // margin: 30px;
        > li {
          font-size: 14px;
          line-height: 1.4em;

          margin: 15px;

          word-wrap: break-word;
          font-size: 1.1rem;

          color: #999999;
          a {
            color: inherit;
          }

          &:first-child {
            display: block;
            margin-bottom: 10px;
            color: #fff;
            font-size: 18px;
          }
          &.tel {
            color: #00c9a1;
            font-size: 18px;
          }
        }
      }
    }
  }
  .footerBottom {
    border-top: 1px solid #666666;
    p {
      width: 100%;
      margin: 0px auto;
      line-height: 1.5rem;
      text-align: center;
      color: #666666;
      &:first-child {
        padding-top: 20px;
      }
      .alink {
        color: #666666;
        text-decoration: none;
        display: inline-block;
        margin-left: 1rem;
        .gxbImg {
          height: 1.2rem;
        }
      }
    }
  }
}
</style>

